<template>
    <v-container class="pa-0 ma-0">
        <v-row>
            <v-col class="col-3" align="left" style="word-break: normal !important">
                {{ title }}
            </v-col>
            <v-col class="col-9">
                <btn
                    :disabled="disableAll"
                    v-if="!hideRun"
                    @click="runStep()"
                    small
                >
                    <v-progress-circular
                        v-if="!!runLabel"
                        indeterminate
                        :size="20"
                        :width="1"
                        class="mr-3"
                    />
                    {{ runLabel ? runLabel : 'Run' }}
                </btn>
                <fragment v-if="!showStep && AFId && !runLabel">
                    <btn
                        :color="color"
                        @click="showActionFlow()"
                        small
                        :key="color"
                        label="Show Results"
                    />
                    <p
                        class="yellow--text"
                        v-if="actionFlowData && actionFlowData[0] && actionFlowData[0].actionNote"
                    >
                        - {{ actionFlowData[0].actionNote }}
                    </p>
                    <p
                        class="error--text"
                        v-if="actionFlowData && actionFlowData[0] && actionFlowData[0].actionError"
                    >
                        - {{ actionFlowData[0].actionError }}
                    </p>
                </fragment>
                <btn v-if="showStep" @click="hideActionFlow()" small>Hide Results</btn>
            </v-col>
        </v-row>
        <v-row v-if="actionFlowData.length && showStep">
            <v-col class="col-1"></v-col>
            <v-col class="col-11">
                <action-flow
                    :action-flow-data="actionFlowData"
                    :key="JSON.stringify(actionFlowData)"
                    open-all
                />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import api from '@/api';
import ActionFlow from '@/components/common/ActionFlow';

export default {
    name: 'ActionFlowStep',
    props: {
        runStep: Function,
        title: String,
        runLabel: String,
        step: String,
        AFId: String,
        hideRun: Boolean,
        disableAll: Boolean,
    },
    components: { ActionFlow },
    data: () => ({
        actionFlowData: [],
        color: '',
        status: '',
        showStep: false,
    }),
    methods: {
        getStatus() {
            if (this.actionFlowData.length) {
                const actionFlow = this.actionFlowData.find(oneAF => oneAF.rootId === this.AFId);
                return actionFlow ? actionFlow.actionStatus : 'none';
            }
            return 'error';
        },
        async loadActionFlow(isParentOnly) {
            if (this.AFId) {
                if (isParentOnly) {
                    const result = await api.get(this.$store, `actionflow/root/${this.AFId}/true`);
                    this.actionFlowData = result.data || [];
                } else {
                    const result = await api.get(this.$store, `actionflow/root/${this.AFId}`);
                    this.actionFlowData = result.data || [];
                }
                this.status = this.getStatus();
            }
            this.color = this.getColor;
            return this.AFId;
        },
        async showActionFlow() {
            await this.loadActionFlow();
            if (this.AFId) {
                this.showStep = true;
            }
        },
        hideActionFlow(step) {
            this.showStep = false;
        },
    },
    computed: {
        getColor() {
            if (this.status === 'success') return 'green';
            if (this.status === 'error') return 'red';
            return 'orange';
        },
    },
    watch: {
        async AFId() {
            await this.loadActionFlow(!this.showStep);
        },
    },
    async mounted() {
        this.actionFlowId = this.AFId;
        await this.loadActionFlow(true);
    },
};
</script>
<style></style>
